<template>
  <div class="tab-pane fade" id="nav-full" role="tabpanel" aria-labelledby="nav-full-tab">
    <div class="compositionarea_topber d-flex" :class="{'align-items-center justify-content-between': device==='desktop', 'flex-column': device==='mobile'}">
      <div class="compositionarea_topber_left">
        <div class="compositiondropdown_box d-flex align-items-center justify-content-between">
          <h4>{{$t("sabre.search-panel.nights")}}</h4>
          <spin-plus-minus-button v-model="nights"/>
        </div>
      </div>
      <div class="compositionarea_topber_right d-flex align-items-center">

        <date-info-tooltip/>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="weekend" value="" :checked="weekendTargeted" @change="weekendChanged">
          <label class="form-check-label" for="weekend">{{$t("sabre.search-panel.weekend")}}</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="weekend1" value="option5" :checked="midweekTargeted" @change="midweekChanged">
          <label class="form-check-label" for="weekend1">{{$t("sabre.search-panel.midweek")}}</label>
        </div>
      </div>
    </div>
    <div class="compositionarea_body">
      <div class="row g-3 row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5">
        <div class="checkbox col" v-for="(item, inx) in availableMonths" :key="inx">
          <label class="checkbox-wrapper">
            <input type="checkbox" class="checkbox-input" :checked="storedFullMonths.includes(item.id)" @change="() => addThisMonth(item.id)"/>
            <span class="checkbox-tile">{{item.month}} {{item.year}}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="compositionarea_footer" v-if="device==='desktop'">
      <div class="btn-group-area d-flex justify-content-end">
        <button class="cleanup_btn" @click="clearUp">{{$t("sabre.buttons.clean")}}</button>
        <button class="save_btn" @click="chooseDate">{{$t("sabre.buttons.save")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'sabre-date-range',
  components: {
    DateInfoTooltip: () => import('./dateInfoTooltip'),
    SpinPlusMinusButton: () => import('@/sabre/common/atom/spinPlusMinusButton'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      storedFullMonths: 'GET_SABRE_FULL_MONTHS',
      storedNights: 'GET_SABRE_NIGHTS_FOR_SEARCH',
      weekendTargeted: 'GET_SABRE_WEEKEND_TARGETED_FOR_SEARCH',
      midweekTargeted: 'GET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH',
    }),
    availableMonths() {
      const start = dayjs(new Date());
      const monthsStrList = Object.values(this.$t('monthName'));
      const monthsArray = [];
      for (let i = 0; i < 15; i += 1) {
        const c = start.add(i, 'month');
        const year = c.year();
        const month = c.month();
        monthsArray.push({ id: c.format('YYYY-MM'), year, month: monthsStrList[month] });
      }

      return monthsArray;
    },
  },
  watch: {
    nights() {
      this.$store.commit('SET_SABRE_NIGHTS_FOR_SEARCH', this.nights);
    },
    storedNights() {
      this.nights = +this.storedNights;
    },
  },
  data() {
    return {
      nights: 0,
    };
  },
  mounted() {
    this.nights = this.storedNights;
  },
  methods: {
    addThisMonth(id) {
      this.$store.commit('SET_SABRE_FULL_MONTHS', { item: id });
    },
    clearUp() {
      this.storedFullMonths.forEach((item) => {
        this.$store.commit('SET_SABRE_FULL_MONTHS', { item });
      });
    },
    chooseDate() {
      this.$emit('close');
    },
    weekendChanged() {
      this.$store.commit('SET_SABRE_WEEKEND_TARGETED_FOR_SEARCH', !this.weekendTargeted);
    },
    midweekChanged() {
      this.$store.commit('SET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH', !this.midweekTargeted);
    },
  },
};

</script>
